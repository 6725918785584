import React from 'react'
import { useSelector } from 'react-redux'
import { Inasistencias } from './Inasistencias'

export const InasistenciaScreen = () => {
    const  { inasistencias } = useSelector( state => state.inasistencia)
    return (
        <div className="row">
            <div className="col-12">
                <h3 className="mt-3 text-light">Administrador de inasistencias</h3>
            </div>
            <div className="col-12 mt-3">
                <Inasistencias  inasistencias={inasistencias} />
            </div>
        </div>
    )
}
