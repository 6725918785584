import React from 'react'
import { useDispatch } from 'react-redux';
import { crearCurso } from '../../../action/curso';
import { useForm } from '../../../hooks/useForm'

export const FormCurso = () => {
    const dispatch = useDispatch()
    const [curso, handleInputChange, reset] =useForm({
        nombre:'',
    })
    const { nombre } = curso;
    
    const handleGuardar = (e) => {
        e.preventDefault();
        curso.periodoEscolar = '2021'
        dispatch(crearCurso(curso))
        reset()
    }
    return (
        <form className="card">
            <div className="card-body">
                <h5>Ingreso nuevo curso</h5>
                <div className="mb-3">
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input 
                        type="nombre" 
                        className="form-control" 
                        id="nombre" 
                        name="nombre"
                        value={nombre}
                        onChange={handleInputChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary" onClick={ handleGuardar }>Guardar</button>
            </div>
        </form>
    )
}