import React from 'react'
import { useDispatch } from 'react-redux'
import { openCursos, openEstudiantes, openInasistencias } from '../../action/admin'

export const MenuAdmin = () => {
    const dispatch = useDispatch()

    return (
        <div className="row">
            <div className="col-12 mt-3">
                <div className="d-grid gap-2">
                    <button className="btn btn-primary" onClick={ () => dispatch(openInasistencias()) } >Inasistencia</button>
                    <button className="btn btn-primary" onClick={ () => dispatch(openCursos()) } >Cursos</button>
                    <button className="btn btn-primary" onClick={ () => dispatch(openEstudiantes()) } >Estudiantes</button>
                </div>
                
            </div>
        </div>
    )
}
