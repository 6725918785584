import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux'

export const FichaInasistencia = () => {
    
    let {apoderadoActivo:apoderado} = useSelector( state => state.apoderado);
    let {estudianteActivo:estudiante} = useSelector( state => state.estudiante);
    let {inasistenciaActiva:inasistencia} = useSelector( state => state.inasistencia);
    const {cursos} = useSelector( state => state.curso)
    let cursoActivo = cursos.find(e => e._id === estudiante.curso)
    if(!apoderado){
        apoderado={
            nombres:'',
            apellidoP:'',
            apellidoM:'',
            rut:'',
        }
    }
    if(!estudiante){
        estudiante={
            nombres:'',
            apellidoP:'',
            apellidoM:'',
            rut:'',
            curso:'',
        }
    }
    if(!inasistencia){
        estudiante={
            motivo:'',
            fechaInicio:'',
            fechaTermino:'',
            descripcion:'',
        }
    }
    if(!cursoActivo){
        cursoActivo={
            nombre:'',
        }
    }
    
    return (
        <div className="card">
            <div className="card-body">
                <h3>Ficha Inasistencia </h3>
                <h5 className="mt-3">Datos estudiante</h5>
                <table className="table table-striped table-bordered table-hover">
                    <tbody>
                        <tr>
                            <td><strong>Nombre:</strong></td>
                            <td>{`${estudiante.nombres} ${estudiante.apellidoP} ${estudiante.apellidoM}`}</td>
                            <td><strong>Rut:</strong></td>
                            <td>{estudiante.rut}</td>
                        </tr>
                        <tr>
                            <td><strong>curso:</strong></td>
                            <td>{cursoActivo.nombre}</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="mt-3">Datos apoderado</h5>
                <table className="table table-striped table-bordered table-hover">
                    <tbody>
                        
                        <tr>
                            <td><strong>Nombre:</strong></td>
                            <td>{`${apoderado.nombres} ${apoderado.apellidoP} ${apoderado.apellidoM}`}</td>
                            <td><strong>Rut:</strong></td>
                            <td>{apoderado.rut}</td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="mt-3">Datos Inasistencia</h5>
                <table className="table table-striped table-bordered table-hover">
                    <tbody>
                        <tr>
                            <td><strong>Motivo:</strong></td>
                            <td>{inasistencia.motivo}</td>
                            <td><strong>Fecha inicio:</strong></td>
                            <td>{moment(inasistencia.fechaInicio).format('DD-MM-YYYY')}</td>
                            <td><strong>Fecha termino:</strong></td>
                            <td>{moment(inasistencia.fechaTermino).format('DD-MM-YYYY')}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-striped table-bordered table-hover">
                    <tbody>
                        <tr>
                            <td><strong>descripcion:</strong></td>
                            <td><p>{inasistencia.descripcion}</p></td>
                        </tr>
                    </tbody>
                </table>
                {inasistencia.documento && 
                    <>
                        <h5 className="mt-3">Documento</h5>
                        <img src={inasistencia.documento} className="img-fluid" alt="documento inasistencia" />
                    </>
                }
            </div>
        </div>
    )
}