import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openFormActualizarCurso } from '../../../action/admin';
import { activarCurso, actualizarCurso } from '../../../action/curso';
import { useForm } from '../../../hooks/useForm';
import { Loading } from '../../Loading';

export const FormActualizarCurso = () => {
    const {cursoActivo} = useSelector(state => state.curso);
    const dispatch = useDispatch()
    const [curso, handleInputChange] =useForm(cursoActivo)
    const { nombre } = curso;
    
    const handleActualizar = (e) => {
        e.preventDefault();
        dispatch(actualizarCurso(curso))
        dispatch(openFormActualizarCurso(false))
    }
    const handleCancelar = (e) => {
        e.preventDefault();
        dispatch(openFormActualizarCurso(false));
        dispatch(activarCurso({}))
    }
    if(!cursoActivo){
        return <Loading />
    }
    return (
        <form className="card">
            <div className="card-body">
                <h5>Actualizar datos curso</h5>
                <div className="mb-3">
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input 
                        type="nombre" 
                        className="form-control" 
                        id="nombre" 
                        name="nombre"
                        value={nombre}
                        onChange={handleInputChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary" onClick={ handleActualizar }>Guardar</button>
                <button type="submit" className="btn btn-danger" onClick={ handleCancelar }>cancelar</button>
            </div>
        </form>
    )
}
