import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  validate, format } from 'rut.js'
import Swal from 'sweetalert2';
import { openEstudiantes } from '../../../action/admin';
import { actualizarEstudiante } from '../../../action/estudiante';
import { useForm } from '../../../hooks/useForm';
import { Loading } from '../../Loading';


export const ActualizarEstudiante = () => {
    const dispatch = useDispatch()
    const {estudianteActivo} = useSelector( state => state.estudiante);
    const [estudiante, handleInputChange ] = useForm(estudianteActivo)

    const handleRutEstudiante = () => {
        if(!validate(format(estudiante.rut))) {
            return Swal.fire({
                icon: 'warning',
                title: 'El rut no es valido',
                showConfirmButton: false,
                timer: 1500
            })
        } 
    }
    const handleActualizar = (e) => {
        e.preventDefault();
        if(!validate(format(estudiante.rut))) {
            return Swal.fire({
                icon: 'warning',
                title: 'El rut no es valido',
                showConfirmButton: false,
                timer: 1500
            })
        } 
        if( estudiante.nombres.trim() === '' || estudiante.apellidoM.trim() === '' || estudiante.apellidoP.trim() === '' || estudiante.rut.trim() === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan datos estudiante',
                showConfirmButton: false,
                timer: 1500
            })
        }
        dispatch(actualizarEstudiante(estudiante))
        dispatch(openEstudiantes())


    }

    if(!estudianteActivo) return <Loading />
    return (
        <div>
            <form className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h4>Datos Estudiante</h4>
                        </div>
                        <div className="col-4">
                            <div className="mb-3">
                                <label htmlFor="rut" className="form-label">Rut Estudiante: </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="rut" 
                                    name="rut"
                                    value={format(estudiante.rut)} 
                                    onChange={handleInputChange}
                                    onBlur={handleRutEstudiante}
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-3">
                                <label htmlFor="nombres" className="form-label"> Nombres: </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="nombres" 
                                    name="nombres"
                                    value={estudiante.nombres} 
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="mb-3">
                                <label htmlFor="apellidoP" className="form-label">Apellido Paterno: </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="apellidoP" 
                                    name="apellidoP"
                                    value={estudiante.apellidoP} 
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="mb-3">
                                <label htmlFor="apellidoM" className="form-label">Apellido Materno: </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="apellidoM" 
                                    name="apellidoM"
                                    value={estudiante.apellidoM} 
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary" 
                    onClick={ handleActualizar }
                    // onFocus={ handleFocusEnviar }
                >Enviar</button>
            </form>
        </div>
    )
}
