import React from 'react'
import { Curso } from './Curso'

export const Cursos = ({cursos}) => {
    return (
        <table className="table table-dark table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Actualizar</th>
                    <th scope="col">Eliminar</th>
                </tr>
            </thead>
            <tbody>
                { cursos && 
                    cursos.map( e => (
                        <Curso 
                            key={e._id} 
                            curso={e}
                        /> 
                    ))
                }
            </tbody>
        </table>
    )
}
