import React from 'react'
import { useSelector } from 'react-redux';
import { Estudiantes } from './Estudiantes'

export const EstudianteScreen = () => {
    const { estudiantes } = useSelector( state => state.estudiante );
    return (
        <div className="row">
            <div className="col-12">
                <h3 className="mt-3 text-light">Administrador de estudiantes</h3>
            </div>
            <div className="col-12 mt-3">
                <Estudiantes estudiantes={estudiantes} />
            </div>
        </div>
    )
}
