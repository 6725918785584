import React from 'react'
import { Estudiante } from './Estudiante'

export const Estudiantes = ({estudiantes}) => {
    return (
        <table className="table table-dark table-striped">
            <thead>
                <tr>
                    {/* <th scope="col">#</th> */}
                    <th scope="col">Rut</th>
                    <th scope="col">Estudiante</th>
                    <th scope="col">Actualizar</th>
                    <th scope="col">Eliminar</th>
                </tr>
            </thead>
            <tbody>
                { estudiantes && 
                    estudiantes.map(e=> (
                        <Estudiante 
                            key={e._id}
                            estudiante={e}
                        />
                    ))
                }
            </tbody>
        </table>
    )
}
