import React from "react"
import Layout from '../components/layout'

import { AdminScreen } from "../components/administrador/AdminScreen"


// markup
const Administrador = () => {
  
  return (
    <Layout>
      <AdminScreen />
    </Layout>
  )
}

export default Administrador
