import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { openFormActualizarCurso } from '../../../action/admin';
import { activarCurso, eliminarCurso } from '../../../action/curso';

export const Curso = ({curso}) => {
    const dispatch = useDispatch()
    const {nombre} = curso;
    
    const handleActualizar = () => {
        dispatch(activarCurso(curso))
        dispatch(openFormActualizarCurso(true))
    }

    const handleEliminar = () => {
        Swal.fire({
            title: 'Esta seguro que quiere eliminar el curso?',
            text: "Los datos no se podran recuperar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(eliminarCurso(curso))
            }
        })
    }
    return (
        <tr>
            <th scope="row">1</th>
            <td>{nombre}</td>
            <td>
                <button className="btn btn-warning btn-sm" onClick={handleActualizar}>
                    <i className="far fa-edit"></i>
                </button>
            </td>
            <td>
                <button className="btn btn-danger btn-sm" onClick={handleEliminar}>
                    <i className="far fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    )
}
