import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { openFichaInasistencia } from '../../../action/admin';
import { activarApoderado } from '../../../action/apoderado';
import { activarEstudiante } from '../../../action/estudiante';
import { activarInasistencia } from '../../../action/inasistencia';

export const Inasistencia = ({inasistencia}) => {
    const dispatch = useDispatch();

    const {estudiantes} = useSelector( state => state.estudiante);
    const {apoderados} = useSelector( state => state.apoderado);

    let estudianteInasistencia = estudiantes.find(estudiante => estudiante._id === inasistencia.estudiante);
    let apoderadoInasistencia = apoderados.find(apoderado => apoderado._id === inasistencia.apoderado);

    if(!estudianteInasistencia) {
        estudianteInasistencia = {
            nombres:'',
            apellidoP:'',
            apellidoM:'',
        }
    }

    const { nombres, apellidoP, apellidoM } = estudianteInasistencia;
    const { motivo, descripcion, documento, fechaInicio, fechaTermino } = inasistencia
    
    const handleRevisar = () => {
        dispatch(activarApoderado(apoderadoInasistencia));
        dispatch(activarEstudiante(estudianteInasistencia));
        dispatch(activarInasistencia(inasistencia));
        dispatch(openFichaInasistencia());
    }

    return (
        <tr>
            <td>{ `${nombres} ${apellidoP} ${apellidoM}` }</td>
            <td>{motivo}</td>
            <td>{`${descripcion.slice(0,200)} ...`}</td>
            <td>{documento ? 'con documento' : 'sin documento'}</td>
            <td>{moment(fechaInicio).format('DD-MM-YYYY')}</td>
            <td>{moment(fechaTermino).format('DD-MM-YYYY')}</td>
            <td>
                <button className="btn btn-primary btn-sm" onClick={ handleRevisar }>
                    <i className="fas fa-search"></i>
                </button>
            </td>
        </tr>
    )
}
