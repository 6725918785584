import React from 'react'
import { Inasistencia } from './Inasistencia'

export const Inasistencias = ({inasistencias}) => {
    return (
        <table className="table table-dark table-striped">
            <thead>
                <tr>
                    <th scope="col">Estudiante</th>
                    <th scope="col">Motivo</th>
                    <th scope="col">Descripcion</th>
                    <th scope="col">Documento</th>
                    <th scope="col">Fecha inicio</th>
                    <th scope="col">Fecha termino</th>
                    <th scope="col">Revisar</th>
                </tr>
            </thead>
            <tbody>
                {inasistencias && inasistencias.map(e=> (
                    <Inasistencia 
                        key={e._id}
                        inasistencia={e} 
                    />
                ))}
            </tbody>
        </table>
    )
}

