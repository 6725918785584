import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { openActualizarEstudiante } from '../../../action/admin';
import { activarEstudiante, eliminarEstudiante } from '../../../action/estudiante';

export const Estudiante = ({estudiante}) => {
    const dispatch = useDispatch()
    const { rut, nombres, apellidoP, apellidoM } = estudiante;
    const handleActualizar = ()=> {
        dispatch(activarEstudiante(estudiante))
        dispatch(openActualizarEstudiante())
    }
    const handleEliminar = () => {
        Swal.fire({
            title: 'Esta seguro en elimina el estudiante?',
            text: "Los datos no se podran recuperar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(eliminarEstudiante(estudiante))
            }
        })
    }
    return (
        <tr>
            {/* <th scope="row">1</th> */}
            <td>{rut}</td>
            <td>{`${ nombres} ${ apellidoP } ${ apellidoM }`}</td>
            <td>
                <button className="btn btn-warning btn-sm" onClick={ handleActualizar }>
                    <i className="far fa-edit"></i>
                </button>
            </td>
            <td>
                <button className="btn btn-danger btn-sm" onClick={ handleEliminar }>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    )
}

