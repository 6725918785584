import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";

import { Loading  } from "../Loading";
import { MenuAdmin } from "./MenuAdmin";
import { CursoScreen } from "./curso/CursoScreen";
import { EstudianteScreen } from "./estudiante/EstudianteScreen";
import { InasistenciaScreen } from "./inasistencia/InasistenciaScreen";

import { startChecking } from '../../action/auth';
import { obtenerCursos } from '../../action/curso';
import { obtenerEstudiantes } from '../../action/estudiante';
import { obtenerInasistencias } from "../../action/inasistencia";
import { obtenerApoderados } from "../../action/apoderado";
import { FichaInasistencia } from "./inasistencia/FichaInasistencia";
import { ActualizarEstudiante } from "./estudiante/ActualizarEstudiante";

export const AdminScreen = () => {
    const dispatch = useDispatch()
    const { checking, uid } = useSelector( state => state.auth );
    const { cursosScreen , estudiantesScreen, inasistenciasScreen, fichaInasistenciaScreen, actualizarEstudiante } = useSelector( state => state.admin );

    useEffect(() => {
        dispatch(startChecking());
        const obtCursos = () => dispatch(obtenerCursos());
        const obtEstudiantes = () => dispatch(obtenerEstudiantes());
        const obtInasistencias = () => dispatch(obtenerInasistencias());
        const obtApoderados = () => dispatch(obtenerApoderados());
        obtCursos();
        obtEstudiantes();
        obtInasistencias();
        obtApoderados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if ( checking ) {
        return (
            <Loading />
        )
    } else {
        if(!uid){
            setTimeout(() => {
                navigate("/login/")
            }, 1500);
        }
    }
    if(!uid){
        return (
            <Loading />
        )
    }
    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-2">
                    <MenuAdmin />
                </div>
                <div className="col-10 mt-3">
                    { cursosScreen && <CursoScreen /> }
                    { estudiantesScreen  && <EstudianteScreen /> }
                    { inasistenciasScreen  && <InasistenciaScreen /> }
                    { fichaInasistenciaScreen && <FichaInasistencia /> }
                    { actualizarEstudiante && <ActualizarEstudiante /> }
                </div>
            </div>
        </div>
    )
}
