import React from 'react'
import { useSelector } from 'react-redux';
import { Cursos } from './Cursos'
import { FormActualizarCurso } from './FormActualizarCurso';
import { FormCurso } from './FormCurso';

export const CursoScreen = () => {
    const { cursos } = useSelector( state => state.curso );
    const { formActualizarCurso } = useSelector( state => state.admin );
    return (
        <div className="row">
            <div className="col-12">
                <h3 className="mt-3 text-light">Administrador de cursos</h3>
            </div>
            <div className="col-9 mt-3">
                <Cursos cursos={cursos} />
            </div>
            <div className="col-3 mt-3">
                
                {formActualizarCurso ? <FormActualizarCurso /> : <FormCurso />}
                
            </div>
        </div>
    )
}
